/* .mediaNavbar{
    background: linear-gradient(109.95deg, #1 12.19%, #14A144 55.55%, #FFF 98.9%);
  mix-blend-mode: darken;
  height: 100%;
  color: white;
} */

.top_navBox {
  /* background: url("../Img/MangoMedicalBanner.svg") ,no-repeat; */
  background-position: center;
  height: 715px;
  width: 100%;
  background-size: cover;
  background: linear-gradient(30deg, #f68e1e 30%, #f29f3f 50%, #efad59 90%);
  mix-blend-mode: darken;
  /* text-align: center; */
  position: relative;
}

.img_Wave {
  bottom: -50px;
  display: flex;
  width: 100%;
  /* aspect-ratio: 3/2; */
  justify-content: center;
}

/* .img_Wave img{
    
} */
.top_tabs {
  background: url("../Img/Rectangle 1.png") no-repeat;
  background-position: center;
  height: 1200px;
  width: 100%;
  display: flex;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
}

.MuiCard-root {
  margin: 0 auto;
}

.carddrowerlast {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */

  letter-spacing: -0.02em;

  color: #091a2a;
}

.css-1riujay {
  width: 50%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.scrollimg2 {
  position: absolute;
  top: 50%;
  right: 10%;
}

.navpara {
  width: 75%;
}

.logo_img {
  width: 180px;
  height: 100px;
}

.skullimg {
  z-index: 9999999999 !important;
  max-width: 95%;
}

/* @media (max-width:2560px) {
  .wave1_nav{
    bottom: -40px;
    width: 100%;
    height: 300px;
   
  
  }
}
@media (max-width:1440px) {
  
} */

@media (max-width: 1280px) {
  .skullimg {
    width: 100%;

    /* transform: scale(1.1); */
    padding-top: 50px;
  }
}

/*  dots   */
.css-1m9128y {
  display: none;
}
@media (max-width: 360px) {
  .skullimg {
    height: 160px;
  }
}
