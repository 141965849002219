.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.css-eifodq-MuiButtonBase-root-MuiTab-root.Mui-disabled,
.css-13ippc8 {
  color: white !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.aadil {
  display: flex;
}

.swiper-slide {
  width: 355px !important;
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.css-1aquho2-MuiTabs-indicator,
.css-ttwr4n {
  border: 3px solid white !important;
  border-radius: 10px;
  height: 50px !important;
  background-color: transparent !important;
}

.tabsdetail {
  padding: 10px 18px !important;
  overflow: hidden;
}

.scroller {
  margin-top: 60px;
  font-weight: 300;
  font-size: 48px;
  line-height: 100%;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer,
.css-k008qs {
  margin-top: 10px;
}

.css-ktslay-MuiButtonBase-root-MuiIconButton-root,
.MuiButtonBase-root .MuiIconButton-root .MuiIconButton-sizeMedium,
.css-e8vs8u {
  opacity: 0.6 !important;
  /* background-color: rgba(0, 0, 0, 0.04); */
}

.buttonHidden {
  opacity: "1";
}

.swiper-button-prev {
  color: white !important;
  padding: 100% 80px;
  top: 1px !important;
  background: linear-gradient(270.13deg, rgba(255, 255, 255, 0) 19.03%, #FFFFFD 45.76%), linear-gradient(270deg, rgba(255, 255, 255, 0) 6.03%, #FFFFFF 63.76%);
  transform: rotate(0deg);
}

.swiper-button-next {
  background: linear-gradient(271.13deg, rgba(255, 255, 255, 0) 1.03%, #FFFFFF 60.76%), linear-gradient(271.13deg, rgba(255, 255, 255, 0) 6.03%, #FFFFFF 60.76%);
  transform: rotate(180deg);
  color: white !important;
  padding: 100% 80px;
  top: 1px !important;
}

.swiper-button-prev {
  opacity: 1 !important;
  left: -1px !important;
}

.swiper-button-next {
  right: -1px !important;
  opacity: 1 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footertop {
  display: flex;
  justify-content: space-around;
  text-align: left;
  margin-top: 100px;
  margin-bottom: 100px;
}

.footerlast {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
  line-height: 100%;
  /* or 12px */

  letter-spacing: -0.02em;
  text-transform: uppercase;
  padding-left: 83px;
  color: #484848;
}

.footerheading {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 100% !important;
  /* or 20px */

  letter-spacing: -0.02em;
  text-transform: uppercase;

  color: #484848;
}

.footeritem {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 250 !important;
  font-size: 22px !important;
  line-height: 100% !important;
  /* or 22px */
  color: #8d8c8c !important;
  letter-spacing: -0.02em !important;
  text-transform: uppercase !important;

  color: #484848;
}

.footertopborder {
  width: auto;
  display: block;
  margin-top: 5px;
  border: 1px solid rgb(82, 80, 80);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@media (max-width:1162px) {
 .navh3{
  font-size: 40px !important;
 }
}

/* media 1024px */
@media screen and (max-width: 1024px) {
  .skullimg {
    margin-top: -0 !important;
    /* height: 125%; */
  }
  .navh3{
    font-size: 35px !important;
   }
  .css-nhogle-MuiPaper-root-MuiCard-root {
    max-width: 80% !important;
  }
  .navbutton {

    margin-top: 10px !important;
  }
}
@media (max-width: 910px) {
  .navbutton {
font-size: 15px !important;
    margin-top: 10px !important;
  }
}

/* media 768px */
@media screen and (max-width: 768px) {
  .css-1ao61bn-MuiButtonBase-root-MuiButton-root {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .css-dtfle8-MuiButtonBase-root-MuiButton-root {
    margin-left: 0 !important;
  }

  .skullimg {
    margin-top: -0 !important;
    height: 100%;
  }

  .scroller {
    flex-direction: column;
  }

  .css-nhogle-MuiPaper-root-MuiCard-root,
  .css-72xsun {
    max-width: 80% !important;
  }

  .scroller span {
    margin-right: 0 !important;
  }
}

.navcon {
  /* text-align: center; */
}

.sacbut {
  padding: 18px 46px !important;
  font-weight: 600 !important;
  text-transform: none !important;
}
@media (max-width: 787px) {
  .navmenu_box{
    width: 65% !important;
  }

}
@media screen and (max-width: 769px) {
  .navcon {
    padding-top: 0 !important;
    flex-direction: column-reverse;
    align-items: center;
  }

  .css-1ao61bn-MuiButtonBase-root-MuiButton-root {
    margin-left: 0 !important;
  }

  .skullimg {
    height: 220PX;
    padding-top: 0px !important;
  }

  .navpara {
    width: 100% !important;
    text-align: center;

  }

  .navh3 {
    font-size: 26px !important;
    /* text-align: center; */

  }

  .css-1riujay {
    width: 60% !important;
  }

  .navbutton {
    font-size: 10px !important;
    margin-top: 10px !important;
  }

  .navtopcontanct {
    text-align: center;
    width: 100% !important;
    padding-top: 20px !important;

  }

  .scroller span {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 650px) {
  .scroller span {
    margin-right: 0 !important;
  }

  .scroller {
    margin-top: 42px;
    font-size: 20px;
  }

  /* .MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller,.css-k008qs {
  
  width: 370px;
} */
  .css-9tju2d-MuiPaper-root-MuiCard-root {
    min-width: 250px;
    margin-right: 90px;
  }

  .scheduletext {
    font-size: 48px !important;
  }

  .footertop {
    font-size: 80%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 100% 40px;
  }

}

@media screen and (max-width: 450px) {
  .footertop {
    flex-direction: column;
  }

  .footerlast {
    padding-left: 0;
  }

  .scheduletext {
    font-size: 38px !important;
  }

  .displaybr_none {
    display: none;
  }

  .navbutton {
    margin-top: 12px !important;
  }

  .navtopcontanct {
    padding-top: 0 !important;
  }
.cardCoursel{
  margin: 0 auto !important;
}
  .card_swiper {
    width: 280px !important;
    min-width: 280px !important;
    margin: 0 auto !important;
    /* margin-right: 0 !important; */
  }
  .swiper-slide{
    width: 100% !important;
    /* padding: 0 10px ; */
  }
  .swiper-button-prev {
    background: transparent;
  
    color: transparent !important;
  }

  .swiper-button-next {
    background: transparent;
    transform: rotate(0deg);
   /* background-color: brown; */
    color: transparent !important;
  }
}
@media (max-width:375px) {
  .card_swiper {
    width: 250px !important;
    /* margin-right: 0 !important; */
  }
  .swiper-slide{
    width: 100% !important;
    /* padding: 0 10px ; */
  }
  .swiper-button-prev {
    background: transparent;
    margin: auto 0 !important;
  }

  .swiper-button-next {
    background: transparent;
    transform: rotate(0deg);
    margin: auto 0 !important;
  }
}