

h1 {
    text-align: center;
    margin-bottom: 30px;
}

p {
    margin-bottom: 15px;
}

address {
    font-style: normal;
}